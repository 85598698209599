import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navbar } from "../../components/navbar";

import { useNavigate } from "react-router-dom";

import { ReactComponent as CheckIn } from "../../assets/images/checkin.svg";

import "../../assets/scss/index.scss";
import { fetchSettings } from "../../redux/actions/settingsActions";
import { getSettings } from "../../redux/selectors";
import { RootState } from "../../redux/store";
import { toast } from "react-toastify";
import { Visitor } from "../../typings/AppState";
import { adminService } from "../../services/admin";

export const Main = () => {
  const navi = useNavigate();
  const dispatch = useDispatch();

  const { name, error } = useSelector(getSettings);
  const [activeVisitors, setAV] = useState<Visitor[]>([]);

  const getAV = async () => {
    const { data } = await adminService.getActiveVisitors();

    if (data.visitors) setAV(data.visitors);
  };

  useEffect(() => {
    dispatch(fetchSettings());

    getAV();
  }, [dispatch]);

  const handleCheckIn = () => {
    navi("/registration");
  };

  const { checklist } = useSelector((state: RootState) => state.settings);

  const isDisabled = useMemo(() => {
    if (!checklist) return false;
    else if (checklist?.length === 0 || checklist[0].length === 0) return true;
  }, [checklist]);

  useEffect(() => {
    if (isDisabled) {
      toast.error(
        "De bezoekers-registratie module is niet correct ingericht. Neem contact op met ecBase."
      );
    }
  }, [isDisabled]);
  return (
    <Fragment>
      <Navbar />

      {!error && (
        <div className="container">
          <div className="text-center py-10">
            <h1>Welkom bij {name}</h1>
            <h2 className="my-4">Wat gaat u doen?</h2>
            <div className="flex flex-col max-w-[260px] mx-auto">
              <button
                disabled={isDisabled}
                className="button mb-2"
                onClick={handleCheckIn}
              >
                <span>Inchecken</span> <CheckIn />
              </button>
              <button
                disabled={isDisabled}
                className="button inverted"
                onClick={() => navi("/signout")}
              >
                <span>Uitchecken</span> <CheckIn />
              </button>
            </div>

            <p className="py-2 text-gray-500">
              Er {activeVisitors.length === 1 ? "is" : "zijn"} in{" "}
              {activeVisitors.length} bezoeker
              {activeVisitors.length === 1 ? "" : "s"}.
            </p>
          </div>
        </div>
      )}

      {
        /** on error */
        error && (
          <div className="flex flex-col justify-center items-center">
            <p>Er ging iets fout...</p>
          </div>
        )
      }
    </Fragment>
  );
};
