import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

export const CountDownRedirect = () => {
  const [timer, setTimer] = useState(100);
  const to = useNavigate();

  useEffect(() => {
    const un = setInterval(() => {
      setTimer(timer - 0.1);
    }, 10);

    return () => {
      clearInterval(un);
    };
  }, [timer]);

  useEffect(() => {
    if (timer <= 0) {
      to("/");
    }
  }, [timer, to]);

  const bg = useMemo(() => {
    if (timer > 40) return "bg-primary";
    else if (timer <= 40 && timer > 15) return "bg-amber-500";
    else if (timer <= 15) return "bg-red-500";
  }, [timer]);

  return (
    <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
      <div
        className={`${bg} h-2.5 rounded-full`}
        style={{ width: timer + "%" }}
      ></div>
    </div>
  );
};
