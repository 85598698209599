import "./App.css";

import { Dispatch, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { addLoader, removeLoader } from "./redux/actions";

import PrivateRoute from "./components/PrivateRoute";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import { Main } from "./screens/main";
import { Deny } from "./screens/deny";
import { Signout } from "./screens/signout";
import { Signin } from "./screens/signinCode";

import { AppState } from "./typings/AppState";
import { isLoading } from "./redux/selectors";

import { Icon } from "@iconify/react";
import RegistrationForm from "./screens/registration";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { Allow } from "./screens/allow";
import { CheckedOut } from "./screens/checked-out";
import AdminRoute from "./components/AdminRoute";
import AdminLoginScreen from "./screens/admin/login";
import AdminOverview from "./screens/admin/overview";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://7948e0936f75411bbc1d03d7acae29d3@o1000992.ingest.sentry.io/6294481",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const App = () => {
  const loading = useSelector(isLoading);

  useEffect(() => {
    serviceWorkerRegistration.register();
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<Main />} />
            <Route path="/registration" element={<RegistrationForm />} />
            <Route path="/deny" element={<Deny />} />
            <Route path="/allow" element={<Allow />} />
            <Route path="/checked-out" element={<CheckedOut />} />
            <Route path="/signout" element={<Signout />} />
          </Route>

          <Route path="/admin" element={<AdminRoute />}>
            <Route path="overview" element={<AdminOverview />} />
          </Route>

          <Route path="/signin" element={<Signin />} />
          <Route path="/login" element={<AdminLoginScreen />} />
        </Routes>
      </Router>

      <ToastContainer />

      {loading && (
        <div className="App-loader">
          <Icon
            icon="uil:spinner"
            className="App-spinner"
            style={{ fontSize: "3em" }}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  ...state,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  addLoader: () => dispatch(addLoader()),
  removeLoader: () => dispatch(removeLoader()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default Sentry.withProfiler(connector(App));
