import { FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validateClientCode } from "../../redux/actions";
import { getAuth } from "../../redux/selectors";
import { ReactComponent as Next } from "../../assets/images/next.svg";
import { SessionStorage } from "../../services/sessionStorage";

// 111999777888 & 1804
export const ClientCodeForm = () => {
  const [clientCode, setValue] = useState("");
  const dispatch = useDispatch();

  const { error } = useSelector(getAuth);

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    dispatch(validateClientCode({ clientCode }));
  }

  function handleChange(e: any) {
    setValue(e.target.value);
  }

  return (
    <div className="flex flex-col justify-center items-center flex-grow-1">
      <div className="text-red-500 text-xl my-4">{error && error.detail}</div>

      <form onSubmit={handleSubmit}>
        <div className="form-group mb-2">
          <label className="text-white hidden" htmlFor="exampleEmail">
            Klantcode
          </label>

          <input
            type="text"
            name="client-code"
            id="client-code"
            value={clientCode}
            onChange={handleChange}
            placeholder="Klant code invoeren"
          />
        </div>

        <button className={"button-primary button ml-auto"} type="submit">
          <span>Inloggen</span>
          <Next />
        </button>
      </form>
    </div>
  );
};
