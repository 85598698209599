import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navbar } from "../../components/navbar";
import { getSettings } from "../../redux/selectors";
import { ReactComponent as Next } from "../../assets/images/next.svg";
import { useNavigate } from "react-router-dom";
import { Question } from "../../components/question";
import { Question as QuestionInterface } from "../../typings/AppState";
import { checkIn } from "../../redux/actions";

const RegistrationForm = () => {
  const { checklist } = useSelector(getSettings);
  const to = useNavigate();
  const dispatch = useDispatch();

  // the complete form the be filled in
  const [form, setForm] = useState<QuestionInterface[][]>();
  // index of the active checklist array
  const [active, setActive] = useState(0);
  // the active checklist to be shown
  const activeChecklist = useMemo(
    () => (form ? form[active] : []),
    [active, form]
  );

  // init effect to set the form from redux
  useEffect(() => {
    setForm(
      checklist?.map((screen) =>
        screen.map((item) => ({ ...item, answer: "" }))
      )
    );
  }, [checklist]);

  // effect to check if everything has been filled
  useEffect(() => {
    if (!activeChecklist && form) {
      dispatch(checkIn(form, to));
    }
  }, [activeChecklist, to, form, dispatch]);

  // switch to the next checklist
  const handleNext = () => {
    if (!formIsValid) return false;
    setActive(active + 1);
  };

  /**
   * Check the condition and compare it to given answer
   * @param item The question to check the condition for
   * @returns Boolean wether to show the question or not
   */
  const checkItemCondition = (item: QuestionInterface) => {
    // show question if no condition
    if (!item.condition) return true;
    else {
      // search for the checklist (screen) where the id of the condition is found
      const checklistWithConditionItem = form?.find((checklist) =>
        checklist.find((i) => i.id === item.condition?.id)
      );
      // get the individual condition item
      const conditionItem = checklistWithConditionItem?.find(
        (i) => i.id === item.condition?.id
      );

      // switch by comparison
      switch (item.condition.comp) {
        // AND === _equals
        case "AND": {
          return item.condition.value === conditionItem?.answer;
        }
        // OR to be implemented
        case "OR": {
          return true;
        }
        default:
          return false;
      }
    }
  };

  // check if active form is filled in
  const formIsValid = useMemo(() => {
    return (
      (form ? form[active] : [])?.filter(
        (item) => !item.answer && item.type !== -1 && checkItemCondition(item)
      ).length === 0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, active]);

  // function to update the form state
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | string,
    itemIndex: number
  ) => {
    if (!form) return;
    const newData = [...form];
    const value = typeof event === "string" ? event : event.target.value;
    newData[active][itemIndex].answer = value;
    setForm(newData);
  };

  return (
    <>
      <Navbar />

      <div className="container pt-8 flex flex-col gap-4 mb-32">
        {activeChecklist &&
          activeChecklist.length > 0 &&
          activeChecklist.map((item, itemIndex) => {
            const showItem = checkItemCondition(item);
            if (showItem) {
              return (
                <div key={`question-${itemIndex}`}>
                  <Question
                    {...item}
                    onChange={(event) => handleInputChange(event, itemIndex)}
                  />
                </div>
              );
            } else return null;
          })}

        <div className="fixed bottom-0 left-0 right-0 p-4 flex justify-between bg-white">
          <button className="button inverted" onClick={() => to("/signin")}>
            <span className="hidden md:block">Annuleren</span> <Next />
          </button>

          <button
            className="button ml-auto"
            onClick={handleNext}
            disabled={!formIsValid}
          >
            <span className="hidden sm:block">Volgende</span>
            <Next />
          </button>
        </div>
      </div>
    </>
  );
};

export default RegistrationForm;
