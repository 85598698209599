import { Reducer } from "redux";
import { AuthState } from "../../typings/AppState";
import {
  ADD_LOADER,
  CHECKIN_FAILED,
  CHECKIN_START,
  CHECKIN_SUCCESS,
  CHECKOUT_START,
  CHECKOUT_SUCCESS,
  GET_VISITORS_START,
  GET_VISITORS_SUCCESS,
  REMOVE_LOADER,
  VALIDATE_CC_FAILED,
  VALIDATE_CC_START,
  VALIDATE_CC_SUCCESS,
} from "../actions";

const initialState: AuthState = {
  loaderCounter: 0,
  validated: false,
  loading: false,
  error: undefined,
  isCheckedIn: false,
  visitorName: "",
  hasCheckedIn: false,
  visitors: [],
};

const auth: Reducer<AuthState> = (state = initialState, action) => {
  switch (action.type) {
    case ADD_LOADER:
      return {
        ...state,
        loading: true,
        loaderCounter: state.loaderCounter + 1,
      };
    case REMOVE_LOADER:
      return {
        ...state,
        loaderCounter: state.loaderCounter - 1,
        loading: false,
      };

    /**
     *
     * Client Code Validation
     *
     */
    case VALIDATE_CC_START:
      return {
        ...state,
        validated: false,
        error: undefined,
      };

    case VALIDATE_CC_SUCCESS:
      return {
        ...state,
        validated: true,
      };
    case VALIDATE_CC_FAILED:
      return {
        ...state,
        validated: false,
        error: action.result[0],
      };

    /**
     * Check In
     */
    case CHECKIN_START:
      return {
        ...state,
        isCheckedIn: false,
        hasCheckedIn: false,
        visitorName: "",
      };
    case CHECKIN_FAILED:
      return {
        ...state,
        isCheckedIn: false,
        hasCheckedIn: true,
      };
    case CHECKIN_SUCCESS:
      return {
        ...state,
        isCheckedIn: true,
        hasCheckedIn: true,
        visitorName: action.result.vr_name,
      };

    /**
     * Check Out
     */
    case CHECKOUT_START: {
      return {
        ...state,
        visitorName: "",
      };
    }

    case CHECKOUT_SUCCESS:
      return {
        ...state,
        isCheckedIn: false,
        hasCheckedIn: false,
        visitorName: action.result.vr_name,
      };

    /**
     * Get Visitors
     */
    case GET_VISITORS_START:
      return {
        ...state,
        visitors: [],
      };
    case GET_VISITORS_SUCCESS: {
      return {
        ...state,
        visitors: action.result.visitors,
      };
    }
    default:
      return state;
  }
};

export default auth;
