import axios from "axios";
import { apiPath } from ".";


export interface SettingsBody {
    clientCode: string;
}

export interface SettingsError {
    status: number;
    source: string;
    type: string;
    detail: string;
    input: SettingsBody;
}

export interface SettingsResponse {
    errors?: SettingsError[];
    data: SettingsData;
}

interface SettingsData {
    result: boolean;
    settings: any[]
}

export const settingsService = {
    getSettings: async (body: SettingsBody) => {
        const url = `${apiPath}/visitorregistration/get_settings`;
        const { data } = await axios.post<SettingsResponse>(url, body);
        return data;
    }
}