import React, { useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";

interface Props {
  question?: string;
  text: string;
  name?: string;
  id: number;
  type: number;
  answer?: any;
  options: string[];
  onChange: (event: React.ChangeEvent<any> | string) => any;
}

export const Question = ({
  question,
  name,
  id,
  type,
  text,
  answer,
  options,
  onChange,
}: Props) => {
  const [autoComplete, setAutoComplete] = useState<string[]>([]);
  const [open, setOpen] = useState(false);

  // auto complete for input type text with pre filled options
  useEffect(() => {
    if (options.length > 0 && type === 0) {
      setAutoComplete(
        options.filter((item) =>
          item?.toLowerCase().includes(answer?.toLowerCase())
        )
      );
    }
  }, [answer, options, type]);

  // open/close dialog based on input
  useEffect(() => {
    autoComplete.length > 0 && answer.length > 0 && !options.includes(answer)
      ? setOpen(true)
      : setOpen(false);
  }, [autoComplete, answer, options]);

  switch (type) {
    /**
     * Styled HTML
     */
    case -1: {
      return <div dangerouslySetInnerHTML={{ __html: text }}></div>;
    }

    /**
     * Input TEXT
     */
    case 0: {
      return (
        <>
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor={`${id}`}
          >
            {question}
          </label>
          {text && <p>{text}</p>}

          <div className="relative pointer-events-none">
            <input
              className="pointer-events-auto"
              id={`${id}`}
              type="text"
              autoComplete="off"
              value={answer}
              onChange={onChange}
              onBlur={() => setOpen(false)}
            ></input>

            <Transition
              show={autoComplete.length > 0 && open}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <div className="pointer-events-auto absolute z-10 p-3 left-0 top-full -mt-1 right-0 flex flex-col gap-[3px] bg-white rounded-md border shadow-sm">
                {autoComplete.map((e, i) => {
                  return (
                    <div key={e + i}>
                      <div
                        className="cursor-pointer hover:bg-gray-50 px-2 py-1"
                        onClick={() => {
                          onChange(e);
                          setOpen(false);
                        }}
                      >
                        {e}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Transition>
          </div>
        </>
      );
    }
    /**
     * Input SELECT
     */
    case 1: {
      return (
        <>
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor={`${id}`}
          >
            {question}
          </label>
          {text && <p>{text}</p>}
          <select id={`${id}`} value={answer} onChange={onChange}>
            <option value="">--Kies een optie--</option>
            <option value="kantoor">kantoor</option>
            <option value="productieruimte">productieruimte</option>

            {options.map((opt, ind) => {
              return (
                <option key={id + ind} value={opt}>
                  {opt}
                </option>
              );
            })}
          </select>
        </>
      );
    }
    /**
     * Input RADIO
     */
    case 4: {
      return (
        <div className="flex justify-between items-center gap-4">
          <h2 className="block text-gray-700 text-sm font-bold">{question}</h2>
          <div className="flex gap-4">
            <label
              className="block text-gray-700 text-sm font-bold"
              htmlFor={`${id}`}
            >
              Ja
            </label>
            <input
              type="radio"
              id={`${id}`}
              name={name}
              value="ja"
              checked={answer === "ja"}
              onChange={onChange}
            ></input>

            <label
              className="block text-gray-700 text-sm font-bold"
              htmlFor={`${id}`}
            >
              Nee
            </label>
            <input
              type="radio"
              id={`${id}`}
              name={name}
              value="nee"
              checked={answer === "nee"}
              onChange={onChange}
            ></input>
          </div>
        </div>
      );
    }

    /**
     * Input RADIO
     */
    case 6: {
      return (
        <div className="flex justify-between items-center gap-4">
          <h2 className="block text-gray-700 text-sm font-bold">{question}</h2>
          <div className="flex gap-4">
            <input
              type="checkbox"
              id={`${id}`}
              name={name}
              value={answer === "ja" ? "nee" : "ja"}
              checked={answer === "ja"}
              onChange={onChange}
            ></input>
          </div>
        </div>
      );
    }
    default:
      return <div></div>;
  }
};
