import { useState } from "react";
import { NumPadButton } from "../typings/AppState";

export function useNumPad(onSubmit: () => void) {
  const [value, setValue] = useState("");

  const handleClick = (char: string) => {
    setValue(value + char);
  };

  const handleRemove = (char: string) => {
    if (char !== "bsp") {
      return;
    }
    setValue(value.slice(0, -1));
  };

  const handleSubmit = (char: string) => {
    if (char !== "ok") {
      return;
    }
    onSubmit();
    setValue("");
  };

  const onClick = (keyValue: string) => {
    return keyValue === "ok"
      ? handleSubmit(keyValue)
      : keyValue === "bsp"
      ? handleRemove(keyValue)
      : handleClick(keyValue);
  };

  const numPadButtons = [
    [
      { keyValue: "7" },
      { keyValue: "8" },
      { keyValue: "9" },
      {
        keyValue: "bsp",
        className: "pr-0",
        icon: "akar-icons:backspace",
      },
    ] as NumPadButton[],
    [
      { keyValue: "4" },
      { keyValue: "5" },
      { keyValue: "6" },
      {},
    ] as NumPadButton[],
    [
      { keyValue: "1" },
      { keyValue: "2" },
      { keyValue: "3" },
      {},
    ] as NumPadButton[],
    [
      { keyValue: "0", xs: "6" },
      {},
      {},
      {
        keyValue: "ok",
        xs: "6",
        color: "primary",
        buttonStyle: "border-0",
        icon: "akar-icons:check",
      },
    ] as NumPadButton[],
  ];

  return {
    value,
    setValue,
    onClick,
    numPadButtons,
  };
}
