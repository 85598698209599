import React, { Fragment, useEffect, useRef, useState } from "react";
import { Navbar } from "../../components/navbar";

import { ReactComponent as Next } from "../../assets/images/next.svg";
import { Question } from "../../components/question";
import { useDispatch, useSelector } from "react-redux";
import { checkOut, getVisitors } from "../../redux/actions";
import { allVisitors } from "../../redux/selectors";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const Signout = () => {
  const signoutForm = useRef<HTMLFormElement>(null);
  const dispatch = useDispatch();

  const visitors = useSelector(allVisitors);

  const to = useNavigate();

  const [user, setUser] = useState("");

  // retrieve a list of checked in visitors
  useEffect(() => {
    dispatch(getVisitors());
  }, [dispatch]);

  // function to update the form state
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | string
  ) => {
    const value = typeof event === "string" ? event : event.target.value;
    setUser(value);
  };

  // dispatch checkout
  const signOut = () => {
    const selectedUser = visitors.find((u) => u.vr_name === user);
    if (selectedUser) dispatch(checkOut(selectedUser, to));
    else {
      toast.info("Bezoeker niet gevonden...");
    }
  };

  return (
    <Fragment>
      <Navbar />

      <div className="container">
        <div className="py-10">
          <h1>Uitchecken</h1>
          <h3>Vul uw naam in om uit te checken</h3>

          <form className="signout" ref={signoutForm}>
            <Question
              text="Naam"
              answer={user}
              type={0}
              id={0}
              options={visitors.map((i) => i.vr_name)}
              onChange={(e) => handleInputChange(e)}
            />
          </form>
        </div>

        <div className="fixed bottom-0 left-0 right-0 p-4 flex justify-between bg-white">
          <button className="button inverted" onClick={() => to("/signin")}>
            <span className="hidden md:block">Annuleren</span> <Next />
          </button>
          <button onClick={signOut} className={"ml-auto button signoutSubmit"}>
            <span className="hidden sm:block">Uitchecken</span> <Next />
          </button>
        </div>
      </div>
    </Fragment>
  );
};
