import axios from "axios";
import { apiPath } from ".";
import { Visitor } from "../typings/AppState";
import { SessionStorage } from "./sessionStorage";

interface ActiveVisitorResponse {
  data: {
    result: boolean;
    visitors: Visitor[];
    table: { [x: string]: string }[];
  };
}

interface VisitorHistoryResponse {
  data: {
    result: boolean;
    visitors: Visitor[];
    table: { [x: string]: string }[];
  };
}

const getHeaders = () => {
  return {
    "Content-Type": "multipart/form-data",
    Authorization: SessionStorage.getSessionId() || "",
  };
};

export const adminService = {
  getActiveVisitors: async () => {
    const url = `${apiPath}/visitorregistration/get_visitors`;
    const { data } = await axios.post<ActiveVisitorResponse>(url, {
      clientCode: SessionStorage.getClientCode(),
    });
    return data;
  },

  getVisitorHistory: async (filter?: { date: [Date, Date] }) => {
    const url = `${apiPath}/visitorregistration/get_visitor_history`;
    const { data } = await axios.post<VisitorHistoryResponse>(
      url,
      {
        clientCode: SessionStorage.getClientCode(),
        filter,
      },
      {
        headers: getHeaders(),
      }
    );
    return data;
  },
};
