import React from "react";
import { DateElement } from "./DateElement";
import { TimeElement } from "./TimeElement";
import { useDispatch, useSelector } from "react-redux";
import { getSettings } from "../../redux/selectors";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../redux/actions";

interface Props {
  children?: React.ReactNode;
  className?: string;
  date?: string;
  isAdmin?: boolean;
}

export const Navbar = ({ isAdmin = false }: Props) => {
  const { logo, name } = useSelector(getSettings);
  const dispatch = useDispatch();
  const to = useNavigate();
  return (
    <div className="bg-gray-50 left-0 top-0 relative min-h-[100px] flex flex-col justify-center">
      <div className="absolute top-0 right-0 left-0 flex justify-end p-1 text-secondary text-base md:text-lg">
        {!isAdmin ? (
          <div className="flex gap-[0]">
            <Link to="/login">
              <button className="rounded-full text-gray-500 p-1 flex justify-center items-center">
                <span className="iconify" data-icon="charm:key"></span>
              </button>
            </Link>
            <Link to="/">
              <button className="rounded-full text-gray-500 p-1 flex justify-center items-center">
                <span className="iconify" data-icon="bx:home"></span>
              </button>
            </Link>
          </div>
        ) : (
          <div className="">
            <button
              className="rounded-full p-1 flex justify-center items-center"
              onClick={() => {
                dispatch(logout());
                to("/login");
              }}
            >
              <span className="iconify" data-icon="ic:baseline-logout"></span>
            </button>
          </div>
        )}
      </div>
      <div className="flex items-center justify-between p-2 my-auto">
        <div className="max-w-[50px] md:max-w-[unset]">
          <DateElement />
        </div>
        <div className="flex flex-col justify-center items-center">
          {logo && (
            <img
              src={logo}
              className="max-h-[28px] md:max-h-[36px]"
              alt="logo"
            />
          )}
          <div>{name && name}</div>
        </div>

        <div>
          <TimeElement />
        </div>
      </div>
    </div>
  );
};
