import { combineReducers } from "redux";
import auth from "./authReducer";
import settings from "./settingsReducer"

const reducerCombo = combineReducers({
  auth,
  settings
});

export default reducerCombo;
