import axios from "axios";
import { apiPath } from ".";
import { Question, Visitor } from "../typings/AppState";

export interface AuthBody {
  clientCode?: string;
  accessCode?: string;
  password?: string;
  username?: string;
}

export interface AuthError {
  status: number;
  source: string;
  type: string;
  detail: string;
  input: AuthBody;
}

export interface AuthResponse {
  errors?: AuthError[];
  data: AuthData;
}

export interface CheckinBody {
  form: string;
  clientCode: string;
}

export interface CheckinResponse {
  errors?: AuthError[];
  data: CheckinData;
}

export interface GetVisitorsBody {
  clientCode: string;
}

export interface CheckoutBody {
  clientCode: string;
  id?: number;
  name?: string;
}

export interface CheckoutResponse {
  errors?: AuthError[];
  data: CheckoutData;
}

interface CheckinData {
  result: boolean; // is a valid client code or not
  vr_name: string;
}

interface CheckoutData {
  result: boolean; // is a valid client code or not
}

interface AuthData {
  result: boolean; // is a valid client code or not
}

export interface GetVisitorsResponse {
  data: {
    result: boolean;
    visitors: Visitor[];
  };
  errors?: AuthError[];
}

export const authService = {
  validateClientCode: async (body: AuthBody) => {
    const url = `${apiPath}/auth/validate_client_code`;
    const { data } = await axios.post<AuthResponse>(url, body);
    return data;
  },

  checkIn: async (body: CheckinBody) => {
    const url = `${apiPath}/visitorregistration/check_in`;
    const { data } = await axios.post<CheckinResponse>(url, body);
    return data;
  },

  checkOut: async (body: CheckoutBody) => {
    const url = `${apiPath}/visitorregistration/check_out`;
    const { data } = await axios.post<CheckinResponse>(url, body);
    return data;
  },

  login: async (body: AuthBody) => {
    const url = `${apiPath}/auth/login`;
    const { data } = await axios.post<AuthResponse>(url, body);
    return data;
  },

  getVisitors: async (body: GetVisitorsBody) => {
    const url = `${apiPath}/visitorregistration/get_visitors`;
    const { data } = await axios.post<GetVisitorsResponse>(url, body);
    return data;
  },
};
