import { useSelector } from "react-redux";
import { ReactComponent as Denied } from "../../assets/images/deny.svg";
import { CountDownRedirect } from "../../components/CountDownRedirect";
import { Navbar } from "../../components/navbar";
import { getSettings } from "../../redux/selectors";

export const Deny = () => {
  const settings = useSelector(getSettings);
  return (
    <div className="flex flex-col h-screen">
      <Navbar />
      <div className="container flex-1 flex justify-center items-center">
        <div className="text-center">
          <Denied className="fill-primary denied w-40 h-40 mx-auto my-10" />
          <h1>Excuus</h1>
          <h3>
            Uit de vragenlijst is gebleken dat we u geen toegang kunnen verlenen
            tot het betreden van {settings.name}.
          </h3>

          <p className="text-sm py-4">
            Je wordt automatisch teruggeleid naar het hoofdscherm...
          </p>

          <div className="max-w-[300px] my-2 mx-auto">
            <CountDownRedirect />
          </div>
        </div>
      </div>
    </div>
  );
};
