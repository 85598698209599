import axios from "axios";
import { apiPath } from "./services";

const post = async <T extends any>(endpoint: string, body: any) => {
  const url = `${apiPath}${endpoint}`;
  const { data } = await axios.post<T>(url, body);
  return data;
};

const getWithToken = async <T extends any>(
  endpoint: string,
  token: string,
  body?: any
) => {
  const url = `${apiPath}${endpoint}`;

  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    },
    data: body,
  };
  const { data } = await axios.get<T>(url, options);

  return data;
};

const postWithToken = async <T extends any>(
  endpoint: string,
  token: string,
  body?: any
) => {
  const url = `${apiPath}${endpoint}`;

  const { data } = await axios.request<T>({
    method: "post",
    url,
    data: body,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    },
  });

  return data;
};

export const API = {
  getWithToken,
  postWithToken,
  post,
  axios,
};
