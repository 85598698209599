import { useSelector } from "react-redux";
import { CountDownRedirect } from "../../components/CountDownRedirect";
import { Navbar } from "../../components/navbar";
import { getAuth, getSettings } from "../../redux/selectors";

export const CheckedOut = () => {
  const settings = useSelector(getSettings);
  const auth = useSelector(getAuth);
  return (
    <div className="flex flex-col h-screen">
      <Navbar />
      <div className="container flex-1 flex justify-center">
        <div className="text-center">
          <span
            className="iconify text-primary denied w-40 h-40 mx-auto my-10"
            data-icon="uil:signout"
          ></span>
          <h1>Bedankt {auth.visitorName}</h1>
          <h3>Je bent succesvol uitgecheckt bij {settings.name}</h3>

          <p className="text-sm py-4">
            Je wordt automatisch teruggeleid naar het hoofdscherm...
          </p>

          <div className="max-w-[300px] my-2 mx-auto">
            <CountDownRedirect />
          </div>
        </div>
      </div>
    </div>
  );
};
