import { useEffect } from "react";
import { Link, Navigate, Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SessionStorage } from "../services/sessionStorage";
import { getAuth } from "../redux/selectors";
import { validateCCSuccess } from "../redux/actions";

const PrivateRoute = () => {
  const dispatch = useDispatch();

  const { validated } = useSelector(getAuth);
  const history = useNavigate();

  useEffect(() => {
    const savedClientCode = SessionStorage.getClientCode();

    if (savedClientCode && !validated) {
      dispatch(validateCCSuccess());
      history("/");
    }
  }, [validated, dispatch, history]);

  return validated ? <Outlet /> : <Navigate to="/signin" />;
};

export default PrivateRoute;
