import React, { useState, useEffect, Fragment } from "react";
import dayjs from "dayjs";
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);
export const TimeElement = () => {
  const [time, setTime] = useState("");
  const update = () => {
    setTime(dayjs().format("HH:mm"));
  };
  useEffect(() => {
    update();
    const interval = setInterval(() => {
      update();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return <Fragment>{time}</Fragment>;
};
