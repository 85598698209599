import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Navbar } from "../../../components/navbar";
import { NumPad } from "../../../components/NumPad";
import { useNumPad } from "../../../hooks/useNumPad";
import { login, validateClientCode } from "../../../redux/actions";
import { SessionStorage } from "../../../services/sessionStorage";

const AdminLoginScreen = () => {
  const dispatch = useDispatch();
  const to = useNavigate();

  const [useUsernamePassword, setUP] = useState(false);

  const [username, setUN] = useState("");
  const [password, setPW] = useState("");

  const [clientCode, setCC] = useState("");

  const {
    numPadButtons,
    value: accessCode,
    setValue: setQuantity,
    onClick: onNumpadButtonClick,
  } = useNumPad(() => {
    dispatch(validateClientCode({ clientCode }));
    dispatch(login({ accessCode, clientCode }, () => to("/admin/overview")));
  });

  useEffect(() => {
    const cc = SessionStorage.getClientCode();
    if (cc) setCC(cc);
  }, []);

  return (
    <>
      <Navbar />

      <div className="container dense">
        <div className="text-center py-10 mx-auto max-w-[400px]">
          <h1 className="mb-8">Admin login</h1>

          {!clientCode && (
            <Link to="/">
              <div className="border-l-4 border-amber-600 bg-amber-500 text-white py-2 px-4 mb-2 rounded-sm flex gap-6 items-center">
                <div>
                  <span
                    className="iconify text-2xl"
                    data-icon="ant-design:warning-outlined"
                  ></span>
                </div>
                <div className="text-left">
                  Er is geen klantcode ingesteld!
                  <br /> Klik hier om deze aan te passen.
                </div>
              </div>
            </Link>
          )}

          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (useUsernamePassword) {
                dispatch(validateClientCode({ clientCode }));
                dispatch(
                  login({ username, password }, () => to("/admin/overview"))
                );
              }
            }}
            className="flex flex-col gap-2"
          >
            {!useUsernamePassword ? (
              <div className="w-full">
                <input
                  type="text"
                  className="hidden w-full"
                  placeholder="Klantcode"
                  value={clientCode}
                  onChange={(e) => setCC(e.target.value)}
                />
                <input
                  type="password"
                  className="w-full"
                  placeholder="Inlogcode"
                  value={accessCode}
                  disabled
                  readOnly
                />

                <NumPad
                  buttons={numPadButtons}
                  onButtonClick={(e: string) => {
                    onNumpadButtonClick(e);
                  }}
                />
              </div>
            ) : (
              <div className="w-full">
                <input
                  type="text"
                  className="w-full"
                  placeholder="Gebruikersnaam"
                  value={username}
                  onChange={(e) => setUN(e.target.value)}
                />
                <input
                  type="password"
                  className="w-full"
                  placeholder="Wachtwoord"
                  value={password}
                  onChange={(e) => setPW(e.target.value)}
                />
              </div>
            )}

            <div className="flex justify-between items-center">
              <a href="#" onClick={() => setUP(!useUsernamePassword)}>
                {!useUsernamePassword
                  ? "Inloggen met gebruikersnaam en wachtwoord"
                  : "Inloggen met BackOffice inlogcode"}
              </a>
              {useUsernamePassword && (
                <button type="submit" className="button">
                  LOGIN
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AdminLoginScreen;
