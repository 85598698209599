import { Popover, Tab } from "@headlessui/react";

import React, { Fragment, useEffect, useState } from "react";
import { Navbar } from "../../../components/navbar";
import { adminService } from "../../../services/admin";
import { jsPDF } from "jspdf";
import "react-datepicker/dist/react-datepicker.css";
import html2canvas from "html2canvas";

import styles from "./styles.module.scss";
import { Visitor } from "../../../typings/AppState";
import { fetchSettings } from "../../../redux/actions/settingsActions";
import { useDispatch } from "react-redux";
import { map } from "lodash";
import { authService } from "../../../services/auth";
import { SessionStorage } from "../../../services/sessionStorage";
import { toast } from "react-toastify";
import DatePicker, { registerLocale } from "react-datepicker";
import el from "date-fns/locale/nl"; // the locale you want

import dayjs from "dayjs";
registerLocale("nl-NL", el);
const AdminOverview = () => {
  const dispatch = useDispatch();
  const [activeVisitors, setAV] = useState<{
    table: { [x: string]: string }[];
    visitors: Visitor[];
  }>();

  const [isTable, setIsTable] = useState(false);

  const [date, onDateChange] = useState<[Date, Date]>([new Date(), new Date()]);

  const handleDateChange = async (e: [Date, Date]) => {
    onDateChange(e);
    const { data } = await adminService.getVisitorHistory({
      date: e || [new Date(), new Date()],
    });

    setAV(data);
  };

  const saveTable = () => {
    const table = document.body.querySelector("table");
    if (table) {
      var box = table.getBoundingClientRect();
      console.log(box);
      html2canvas(table).then(function (canvas) {
        // document.body.appendChild(canvas);

        var imgData = canvas.toDataURL("image/png");
        var doc = new jsPDF({
          orientation: "portrait",
          unit: "px",
        });

        const imgRatio = box.width / box.height;

        var pom = document.createElement("a");
        pom.setAttribute("href", imgData);
        pom.setAttribute(
          "download",
          `bezoekersregistratie-${dayjs(date[0]).format("ddmmyy")}-${dayjs(
            date[1]
          ).format("ddmmyy")}.png`
        );
        pom.click();
      });
    }
  };

  const tabs = [
    {
      label: "Actieve bezoekers",
      query: async () => {
        const { data } = await adminService.getActiveVisitors();

        setAV(data);
      },
    },
    {
      label: "Geschiedenis",
      query: async () => {
        const { data } = await adminService.getVisitorHistory({
          date: date || [new Date(), new Date()],
        });

        setAV(data);
      },
      filters: () => {
        return (
          <div>
            <Popover className="relative">
              <Popover.Button as="div">
                <button className="flex gap-2 items-center p-2 border-gray-300 border-[1px] rounded-sm">
                  {dayjs(date[0]).format("LL")}{" "}
                  <span
                    className="iconify"
                    data-icon="la:long-arrow-alt-right"
                  ></span>{" "}
                  {dayjs(date[1]).format("LL")}
                </button>
              </Popover.Button>

              <Popover.Panel className="absolute z-10 mt-1">
                <DatePicker
                  className={styles.datepicker}
                  selected={date[0]}
                  onChange={handleDateChange}
                  startDate={date[0]}
                  endDate={date[1]}
                  locale="nl-NL"
                  selectsRange
                  inline
                />
              </Popover.Panel>
            </Popover>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    tabs[0].query();
    dispatch(fetchSettings());
  }, []);

  return (
    <>
      <Navbar isAdmin />

      <div className="px-3">
        <h1 className="text-center my-4">Welkom admin</h1>

        <Tab.Group
          onChange={(index) => {
            tabs[index].query();
          }}
        >
          <Tab.List className="flex gap-3">
            {tabs.map((tab, buttonIndex) => {
              return (
                <Tab key={`tab${buttonIndex}`} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-3 py-2 text-lg md:text-xl ${
                        selected ? "bg-primary text-white" : "bg-gray-50"
                      }`}
                    >
                      {tab.label}
                    </button>
                  )}
                </Tab>
              );
            })}
          </Tab.List>
          <Tab.Panels>
            {tabs.map((panel, panelIndex) => {
              return (
                <Tab.Panel key={`panel${panelIndex}`}>
                  <div className="my-2 flex gap-4 items-center">
                    <h4>{panel.label}</h4>

                    <button
                      onClick={() => saveTable()}
                      className="p-2 flex gap-2 items-center"
                    >
                      <span
                        className="iconify text-gray-700 text-xl"
                        data-icon="akar-icons:cloud-download"
                      ></span>
                      Opslaan
                    </button>
                  </div>

                  {panel.filters && (
                    <div className="py-2">
                      <h5>Filters</h5>
                      {panel.filters()}
                    </div>
                  )}

                  <div
                    className="w-full overflow-x-auto block"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <table className={styles.table}>
                      <thead>
                        <tr>
                          {activeVisitors &&
                            map(activeVisitors.table, (vi, index, key) => {
                              return <th key={index + "th"}>{vi}</th>;
                            })}

                          <th>Acties</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          /**
                           * loop visitors
                           */
                          activeVisitors?.visitors.map((vi, index) => {
                            return (
                              <tr
                                className="align-center"
                                key={`visitor${index}`}
                              >
                                {
                                  /**
                                   * loop table cols
                                   */
                                  activeVisitors &&
                                    map(
                                      activeVisitors.table,
                                      (vt, index, key) => {
                                        return (
                                          <td key={index + "td"}>
                                            {vi[index]}
                                          </td>
                                        );
                                      }
                                    )
                                }

                                <td>
                                  {!vi.vr_check_out && (
                                    <button
                                      className="text-xl"
                                      onClick={async () => {
                                        const clientCode =
                                          SessionStorage.getClientCode();
                                        if (clientCode) {
                                          const re = await authService.checkOut(
                                            {
                                              clientCode,
                                              ...vi,
                                            }
                                          );
                                          if (re.data.result) {
                                            toast.success(
                                              `${re.data.vr_name} is uitgecheckt.`
                                            );
                                            tabs[panelIndex].query();
                                          } else {
                                            toast.error(
                                              "Uitchecken niet gelukt."
                                            );
                                          }
                                        }
                                      }}
                                    >
                                      <span
                                        className="iconify "
                                        data-icon="ic:baseline-logout"
                                      ></span>
                                    </button>
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>

                    {activeVisitors && activeVisitors.visitors.length === 0 && (
                      <h4 className="text-center my-5">
                        Geen bezoekers gevonden...
                      </h4>
                    )}
                  </div>
                </Tab.Panel>
              );
            })}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </>
  );
};

export default AdminOverview;
