import { RootState } from "../store";

export const getAppState = (state: RootState) => state;

export const getAuth = (state: RootState) => state.auth;

export const isLoading = (state: RootState) => state.auth.loading;

export const getSettings = (state: RootState) => state.settings;

export const allVisitors = (state: RootState) => state.auth.visitors;
