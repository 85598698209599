/*
 * Action types
 */

import { SessionStorage } from "../../services/sessionStorage";
import { Dispatch } from "react";
import { addLoader, removeLoader } from ".";
import { SettingsResponse, settingsService } from "../../services/settings";

export const GET_SETTINGS_START = "GET_SETTINGS_START";
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";
export const GET_SETTINGS_FAILED = "GET_SETTINGS_FAILED";

/**
 * Login Action
 * @param {string} access code
 * @param {string} client code
 */

export function fetchSettings() {
  return async (dispatch: Dispatch<(fn: Dispatch<any>) => void>) => {
    dispatch(addLoader());
    dispatch(getSettingsStart());

    const clientCode = SessionStorage.getClientCode();
    if (!clientCode) {
      dispatch(getSettingsFailed());
      dispatch(removeLoader());
      return false;
    }

    try {
      const response = await settingsService.getSettings({ clientCode });
      const { data } = response;

      if (data) {
        dispatch(getSettingsSuccess(data));
      } else {
        dispatch(getSettingsFailed());
      }

      dispatch(removeLoader());
    } catch (error) {
      console.error("Error:", error);
    }
  };
}

/**
 * Start validation
 */
export const getSettingsStart =
  () => (dispatch: Dispatch<ActionGetSettingsStart>) => {
    dispatch({
      type: GET_SETTINGS_START,
    });
  };
interface ActionGetSettingsStart {
  type: typeof GET_SETTINGS_START;
}

/**
 * Start validation
 */
export const getSettingsSuccess =
  (result: SettingsResponse["data"]) =>
  (dispatch: Dispatch<ActionGetSettingsSuccess>) => {
    dispatch({
      type: GET_SETTINGS_SUCCESS,
      result,
    });
  };
interface ActionGetSettingsSuccess {
  type: typeof GET_SETTINGS_SUCCESS;
  result: SettingsResponse["data"];
}

/**
 * Start validation
 */
export const getSettingsFailed =
  () => (dispatch: Dispatch<ActionGetSettingsFailed>) => {
    dispatch({
      type: GET_SETTINGS_FAILED,
    });
  };
interface ActionGetSettingsFailed {
  type: typeof GET_SETTINGS_FAILED;
}
