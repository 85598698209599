import React, { useState, useEffect, Fragment } from "react";
import dayjs from "dayjs";
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);
require("dayjs/locale/nl");
dayjs.locale("nl");
export const DateElement = () => {
  const [time, setTime] = useState("");
  const update = () => {
    setTime(dayjs().format("LL"));
  };
  useEffect(() => {
    update();
  }, []);
  return <Fragment>{time}</Fragment>;
};
