/*
 * Action types
 */

import { SessionStorage } from "../../services/sessionStorage";
import { Dispatch } from "react";
import {
  AuthBody,
  AuthResponse,
  authService,
  CheckinResponse,
  CheckoutResponse,
  GetVisitorsResponse,
} from "../../services/auth";
import { Question, Visitor } from "../../typings/AppState";
import { toast } from "react-toastify";
import axios, { AxiosError } from "axios";
import { API } from "../../api";

export const ADD_LOADER = "ADD_LOADER";
export const REMOVE_LOADER = "REMOVE_LOADER";

/**************************************************************************************************
 ***************************************** Loaders *******************************************
 **************************************************************************************************/

/**
 * Add loader
 */
export const addLoader = () => (dispatch: Dispatch<ActionAddLoader>) => {
  dispatch({
    type: ADD_LOADER,
  });
};
export interface ActionAddLoader {
  type: typeof ADD_LOADER;
}

/**
 * Remove loader
 */
export const removeLoader = () => (dispatch: Dispatch<ActionRemoveLoader>) => {
  dispatch({
    type: REMOVE_LOADER,
  });
};
export interface ActionRemoveLoader {
  type: typeof REMOVE_LOADER;
}

/**************************************************************************************************
 ***************************************** Validate CC *******************************************
 **************************************************************************************************/

export const VALIDATE_CC_START = "VALIDATE_CC_START";
export const VALIDATE_CC_SUCCESS = "VALIDATE_CC_SUCCESS";
export const VALIDATE_CC_FAILED = "VALIDATE_CC_FAILED";

/**
 * Login Action
 * @param {string} access code
 * @param {string} client code
 */

export function validateClientCode(body: AuthBody) {
  return async (dispatch: Dispatch<(fn: Dispatch<any>) => void>) => {
    dispatch(addLoader());
    dispatch(validateCCStart());

    try {
      const response = await authService.validateClientCode(body);
      const { data, errors } = response;

      if (data.result) {
        SessionStorage.setClientCode(body.clientCode);
        dispatch(validateCCSuccess());
      } else {
        dispatch(validateCCFailed(errors));
      }
      dispatch(removeLoader());
    } catch (error) {
      console.error("Error:", error);
    }
  };
}

/**
 * Start validation
 */
export const validateCCStart =
  () => (dispatch: Dispatch<ActionValidateCCStart>) => {
    dispatch({
      type: VALIDATE_CC_START,
    });
  };
interface ActionValidateCCStart {
  type: typeof VALIDATE_CC_START;
}

/**
 * Start validation
 */
export const validateCCSuccess =
  () => (dispatch: Dispatch<ActionValidateCCSuccess>) => {
    dispatch({
      type: VALIDATE_CC_SUCCESS,
    });
  };
interface ActionValidateCCSuccess {
  type: typeof VALIDATE_CC_SUCCESS;
}

/**
 * Start validation
 */
export const validateCCFailed =
  (result: AuthResponse["errors"]) =>
  (dispatch: Dispatch<ActionValidateCCFailed>) => {
    dispatch({
      type: VALIDATE_CC_FAILED,
      result,
    });
  };
interface ActionValidateCCFailed {
  type: typeof VALIDATE_CC_FAILED;
  result: AuthResponse["errors"];
}

/**************************************************************************************************
 ***************************************** Check In *******************************************
 **************************************************************************************************/
export const CHECKIN_START = "CHECKIN_START";
export const CHECKIN_SUCCESS = "CHECKIN_SUCCESS";
export const CHECKIN_FAILED = "CHECKIN_FAILED";

/**
 * Checkin Action
 * @param {string} access code
 * @param {string} client code
 */

export function checkIn(form: Question[][], to: (path: string) => void) {
  return async (dispatch: Dispatch<(fn: Dispatch<any>) => void>) => {
    dispatch(addLoader());
    dispatch(checkinStart());

    const clientCode = SessionStorage.getClientCode();
    if (!clientCode) return false;

    try {
      const response = await authService.checkIn({
        clientCode,
        form: JSON.stringify(form),
      });
      const { data, errors } = response;

      if (data.result) {
        dispatch(checkinSuccess(data));
        to("/allow");
      } else {
        dispatch(checkinFailed(errors));
        to("/deny");
      }
      dispatch(removeLoader());
    } catch (error) {
      console.error("Error:", error);
    }
  };
}

/**
 * Start checkin
 */
export const checkinStart = () => (dispatch: Dispatch<ActionCheckinStart>) => {
  dispatch({
    type: CHECKIN_START,
  });
};
interface ActionCheckinStart {
  type: typeof CHECKIN_START;
}

/**
 * Start validation
 */
export const checkinSuccess =
  (result: CheckinResponse["data"]) =>
  (dispatch: Dispatch<ActionCheckinSuccess>) => {
    dispatch({
      type: CHECKIN_SUCCESS,
      result,
    });
  };
interface ActionCheckinSuccess {
  type: typeof CHECKIN_SUCCESS;
  result: CheckinResponse["data"];
}

/**
 * Start validation
 */
export const checkinFailed =
  (result: AuthResponse["errors"]) =>
  (dispatch: Dispatch<ActionCheckinFailed>) => {
    dispatch({
      type: CHECKIN_FAILED,
      result,
    });
  };
interface ActionCheckinFailed {
  type: typeof CHECKIN_FAILED;
  result: CheckinResponse["errors"];
}

/**************************************************************************************************
 ***************************************** Check Out *******************************************
 **************************************************************************************************/
export const CHECKOUT_START = "CHECKOUT_START";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const CHECKOUT_FAILED = "CHECKOUT_FAILED";

/**
 * Checkin Action
 * @param {string} access code
 * @param {string} client code
 */

export function checkOut(visitor: Visitor, to: (path: string) => void) {
  return async (dispatch: Dispatch<(fn: Dispatch<any>) => void>) => {
    dispatch(addLoader());
    dispatch(checkoutStart());

    const clientCode = SessionStorage.getClientCode();
    if (!clientCode) return false;

    try {
      const response = await authService.checkOut({ clientCode, ...visitor });
      const { data, errors } = response;

      if (data.result) {
        dispatch(checkoutSuccess(data));
        to("/checked-out");
      } else {
        dispatch(checkoutFailed(errors));
        toast.error("Er ging iets mis.");
      }
      dispatch(removeLoader());
    } catch (error) {
      console.error("Error:", error);
    }
  };
}

/**
 * Start checkin
 */
export const checkoutStart =
  () => (dispatch: Dispatch<ActionCheckoutStart>) => {
    dispatch({
      type: CHECKOUT_START,
    });
  };
interface ActionCheckoutStart {
  type: typeof CHECKOUT_START;
}

/**
 * Start validation
 */
export const checkoutSuccess =
  (result: CheckoutResponse["data"]) =>
  (dispatch: Dispatch<ActionCheckoutSuccess>) => {
    dispatch({
      type: CHECKOUT_SUCCESS,
      result,
    });
  };
interface ActionCheckoutSuccess {
  type: typeof CHECKOUT_SUCCESS;
  result: CheckoutResponse["data"];
}

/**
 * Start validation
 */
export const checkoutFailed =
  (result: AuthResponse["errors"]) =>
  (dispatch: Dispatch<ActionCheckoutFailed>) => {
    dispatch({
      type: CHECKOUT_FAILED,
      result,
    });
  };
interface ActionCheckoutFailed {
  type: typeof CHECKOUT_FAILED;
  result: CheckoutResponse["errors"];
}

/**************************************************************************************************
 ***************************************** Get Visitors *******************************************
 **************************************************************************************************/

export const GET_VISITORS_START = "GET_VISITORS_START";
export const GET_VISITORS_SUCCESS = "GET_VISITORS_SUCCESS";
export const GET_VISITORS_FAILED = "GET_VISITORS_FAILED";

/**
 * getVisitors Action
 * @param {string} access code
 * @param {string} client code
 */

export function getVisitors() {
  return async (dispatch: Dispatch<(fn: Dispatch<any>) => void>) => {
    dispatch(addLoader());
    dispatch(getVisitorsStart());

    const clientCode = SessionStorage.getClientCode();
    if (!clientCode) return false;

    try {
      const response = await authService.getVisitors({ clientCode });
      const { data, errors } = response;

      if (data.result) {
        dispatch(getVisitorsSuccess(data));
      } else {
        dispatch(getVisitorsFailed(errors));
      }
      dispatch(removeLoader());
    } catch (error) {
      console.error("Error:", error);
    }
  };
}

/**
 * Start getVisitors
 */
export const getVisitorsStart =
  () => (dispatch: Dispatch<ActiongetVisitorsStart>) => {
    dispatch({
      type: GET_VISITORS_START,
    });
  };
interface ActiongetVisitorsStart {
  type: typeof GET_VISITORS_START;
}

/**
 * Start validation
 */
export const getVisitorsSuccess =
  (result: GetVisitorsResponse["data"]) =>
  (dispatch: Dispatch<ActiongetVisitorsSuccess>) => {
    dispatch({
      type: GET_VISITORS_SUCCESS,
      result,
    });
  };
interface ActiongetVisitorsSuccess {
  type: typeof GET_VISITORS_SUCCESS;
  result: GetVisitorsResponse["data"];
}

/**
 * Start validation
 */
export const getVisitorsFailed =
  (result: AuthResponse["errors"]) =>
  (dispatch: Dispatch<ActiongetVisitorsFailed>) => {
    dispatch({
      type: GET_VISITORS_FAILED,
      result,
    });
  };
interface ActiongetVisitorsFailed {
  type: typeof GET_VISITORS_FAILED;
  result: GetVisitorsResponse["errors"];
}

/**************************************************************************************************
 ***************************************** Login **********************************************
 **************************************************************************************************/
export const VR_LOGIN = "VR_LOGIN";
export const VR_LOGGING_IN = "VR_LOGGING_IN";
export const VR_LOGGED_OUT = "VR_LOGGED_OUT";
export const VR_LOGIN_SUCCESS = "VR_LOGIN_SUCCESS";
export const VR_LOGIN_FAILED = "VR_LOGIN_FAILED";

/**
 * Login Action
 */

export function login(
  body: {
    clientCode?: string;
    accessCode?: string;
    username?: string;
    password?: string;
  },
  cb?: () => void
) {
  return async (
    dispatch: Dispatch<
      (
        fn: Dispatch<
          | ActionAddLoader
          | ActionLoggingIn
          | ActionRemoveLoader
          | ActionLoginSuccess
          | ActionLoginFailed
        >
      ) => void
    >
  ) => {
    dispatch(addLoader());
    dispatch(loggingIn());

    try {
      const response = await authService.login(body);
      const { data } = response;

      if (data.result) {
        SessionStorage.setUserInfo(data);
        dispatch(loginSuccess(data));
        cb && cb();
      } else {
        dispatch(loginFailed(data));
      }
    } catch (error) {
      console.error("Error:", error);
    }

    dispatch(removeLoader());
  };
}

/**
 * Logging in
 */
export const loggingIn = () => (dispatch: Dispatch<ActionLoggingIn>) => {
  dispatch({
    type: VR_LOGGING_IN,
  });
};
interface ActionLoggingIn {
  type: typeof VR_LOGGING_IN;
}

/**
 * Login success
 */
export const loginSuccess =
  (result: unknown) => (dispatch: Dispatch<ActionLoginSuccess>) => {
    dispatch({
      type: VR_LOGIN_SUCCESS,
      result: result,
    });
  };
interface ActionLoginSuccess {
  type: typeof VR_LOGIN_SUCCESS;
  result: unknown;
}

/**
 * Login failed
 */
export const loginFailed =
  (error: unknown) => (dispatch: Dispatch<ActionLoginFailed>) => {
    dispatch({
      type: VR_LOGIN_FAILED,
      error: error,
    });
  };
interface ActionLoginFailed {
  type: typeof VR_LOGIN_FAILED;
  error: unknown;
}

export const VR_AUTHENTICATION_START = "VR_AUTHENTICATION_START";
export const VR_AUTHENTICATION_SUCCESS = "VR_AUTHENTICATION_SUCCESS";
export const VR_AUTHENTICATION_FAILED = "VR_AUTHENTICATION_FAILED";

/**
 * Authenticate session
 * @param {string} session ID
 */
export const authenticate =
  (sessionID: string) =>
  async (
    dispatch: Dispatch<
      (
        fn: Dispatch<
          | ActionAddLoader
          | ActionRemoveLoader
          | ActionAuthSuccess
          | ActionAuthStart
          | ActionAuthFailed
        >
      ) => void
    >
  ) => {
    dispatch(authenticationStart());
    dispatch(addLoader());

    try {
      const response = await API.getWithToken<{
        data: { result: boolean; sid: string };
      }>("/auth/authenticate", sessionID);

      if (response.data && response.data.result) {
        dispatch(authenticationSuccess());
      } else {
        dispatch(authenticationFailed());
        SessionStorage.resetUserInfo();
      }
    } catch (err: any | AxiosError) {
      if (axios.isAxiosError(err)) {
        // Access to config, request, and response
        console.error("Error:", err.toJSON());

        if (err.response?.status === 401) {
          SessionStorage.resetUserInfo();
        }
      } else {
        // Just a stock error
        console.error("Error:", err);
      }
    } finally {
      dispatch(removeLoader());
    }
  };

/**
 * Authentication success
 */
export const authenticationStart =
  () => (dispatch: Dispatch<ActionAuthStart>) => {
    dispatch({
      type: VR_AUTHENTICATION_START,
    });
  };
interface ActionAuthStart {
  type: typeof VR_AUTHENTICATION_START;
}
/**
 * Authentication success
 */
export const authenticationSuccess =
  () => (dispatch: Dispatch<ActionAuthSuccess>) => {
    dispatch({
      type: VR_AUTHENTICATION_SUCCESS,
    });
  };
interface ActionAuthSuccess {
  type: typeof VR_AUTHENTICATION_SUCCESS;
}
/**
 * Authentication failed
 */
export const authenticationFailed =
  () => (dispatch: Dispatch<ActionAuthFailed>) => {
    dispatch({
      type: VR_AUTHENTICATION_FAILED,
    });
  };
interface ActionAuthFailed {
  type: typeof VR_AUTHENTICATION_FAILED;
}

/**
 * Log out
 */
export function logout() {
  return () => {
    SessionStorage.resetUserInfo();
  };
}
