import React from "react";
import clsx from "clsx";
import { NumPadButton } from "../../typings/AppState";

interface Props {
  buttons: NumPadButton[][];
  onButtonClick: (value: string) => void;
}
export const NumPad = ({ buttons, onButtonClick }: Props) => (
  <div className="grid grid-cols-1 gap-2">
    {buttons.map((buttonRow, rowIndex) => (
      <div
        className="grid grid-cols-4 gap-2"
        key={`numpadButtonRow-${rowIndex}`}
      >
        {buttonRow.map((button, buttonIndex) => {
          if (button.keyValue) {
            return (
              <div className="aspect-[4/3]" key={`Button${buttonIndex}`}>
                <button
                  className={`bg-${button.color || "white"} text-${
                    button.color ? "white" : button.color
                  } border-primary rounded-sm border-2 text-primary text-2xl d-flex justify-center align-center w-full h-full text-center`}
                  onClick={() => onButtonClick(button.keyValue)}
                >
                  {button.icon ? (
                    <span className="iconify mx-auto" data-icon={button.icon} />
                  ) : (
                    <span>{button.keyValue}</span>
                  )}
                </button>
              </div>
            );
          } else return <div key={`empty-${buttonIndex}`} />;
        })}
      </div>
    ))}
  </div>
);
