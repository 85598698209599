import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { SessionStorage } from "../services/sessionStorage";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { authenticate } from "../redux/actions";

const AdminRoute = () => {
  const isSignedIn = SessionStorage.isSignedIn();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    toast.dismiss();
  }, [location]);

  useEffect(() => {
    const savedSessionId = SessionStorage.getSessionId();
    dispatch(authenticate(savedSessionId || ""));
  }, []);

  return isSignedIn ? <Outlet /> : <Navigate to="/login" />;
};

export default AdminRoute;
