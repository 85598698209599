import React, { Fragment, useEffect } from "react";
import clsx from "clsx";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import styles from "./login.module.scss";
import { ClientCodeForm } from "./ClientCodeForm";
import { useSelector } from "react-redux";
import { getAuth } from "../../redux/selectors";
import { useNavigate } from "react-router-dom";
import packageJson from "./../../../package.json";

export const Signin = () => {
  const { validated } = useSelector(getAuth);
  const history = useNavigate();

  useEffect(() => {
    if (validated) {
      history("/");
    }
  }, [validated, history]);

  return (
    <Fragment>
      <div className="container flex flex-col justify-center h-screen">
        <Logo className={clsx(styles.logo, "d-flex justify-content-center")} />
        <ClientCodeForm />

        <p className="text-center text-xs text-gray-400 mt-2">
          {packageJson.version}
        </p>
      </div>
    </Fragment>
  );
};
