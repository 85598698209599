import { Reducer } from "redux";
import { SettingsState } from "../../typings/AppState";

import {
  GET_SETTINGS_FAILED,
  GET_SETTINGS_START,
  GET_SETTINGS_SUCCESS,
} from "../actions/settingsActions";

const initialState: SettingsState = {
  name: undefined,
  logo: undefined,
  checklist: undefined,
  activeList: 0,
  error: undefined,
};

const settings: Reducer<SettingsState> = (state = initialState, action) => {
  switch (action.type) {
    /**
     *
     * Get Settings
     *
     */
    case GET_SETTINGS_START:
      return {
        ...state,
        ...initialState,
      };

    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        ...action.result,
      };
    case GET_SETTINGS_FAILED:
      return {
        ...state,
        error: true,
      };

    /**
     * Checklist actions
     *
    case GOTO_NEXT_CHECKLIST:
      return {
        ...state,
        activeList: state.activeList + 1,
      };
      */
    default:
      return state;
  }
};

export default settings;
